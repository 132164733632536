import axios from "../../axios";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";

import CustomToast from "../../reusable/CustomToast";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import { useQuery } from "@tanstack/react-query";
import {
  handleChange,
  yesNoOptions,
} from "../../components/Helpers/HelperFunctions";

const AddEditFaq = ({ show, handleClose, type, faqData, refetchFaq }) => {
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
    status: 0,
    category_id: null,
  });
  const [error, setError] = useState({
    question: "",
    answer: "",
    category_id: "",
  });
  //toast states
  const [toastBody, setToastBody] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(true);

  //dispatch
  const dispatch = useDispatch();

  const fetchFAQCategories = async () => {
    const res = await axios("/faq/category");
    return res.data.data;
  };
  const {
    data: faqCategories = [],
    isLoading: faqCatLoading,
    // error: prodCatError,
  } = useQuery({
    queryKey: ["FAQ-categories"],
    queryFn: fetchFAQCategories,
    enabled: show,
  });

  //useEffects
  useEffect(() => {
    if (type) {
      setFormData({ ...faqData });
    }

    refetchFaq();
  }, [faqData]);

  useEffect(() => {
    dispatch(loadingSet(faqCatLoading));
  }, [faqCatLoading, dispatch]);
  //Functions
  const handleSave = async () => {
    const requiredFields = ["question", "answer", "category_id"];
    let newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `*${field.replace("_", " ")} is mandatory`;
      }
    });
    setError(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setShowToast(true);
      setToastBody("Please fill all the mandatory fields");
      setSuccess(false);
    }
    // If there are no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      dispatch(loadingSet(true));
      const url = type ? `/faq/update/${faqData.id}` : "/faq/add";

      try {
        const res = await axios({
          method: "post",
          url,
          data: formData,
        });

        setShowToast(true);
        setToastBody(res.data.message);
        setSuccess(true);
        refetchFaq();
        handleClose();
      } catch (err) {
        setShowToast(true);
        setToastBody(err.response.data.message);
        setSuccess(false);
      } finally {
        dispatch(loadingSet(false));
      }
    }
  };

  const valuehandler = (arr, value) => arr.find((opt) => opt.value === value);
  console.log(faqCategories, "faq categories");
  const faqCatOptions = faqCategories?.map((faq) => ({
    label: faq.name,
    value: faq.id,
  }));

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose}>
        <ModalHeader closeButton>
          <Modal.Title className="fontweigh-500">
            {type ? "Edit FAQ" : "Add New FAQ"}
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6} className="mb-3">
              <FormGroup id="question">
                <FormLabel className="fontweigh-500">
                  Question<span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  name="question"
                  type="text"
                  placeholder="Question"
                  value={formData.question}
                  onChange={(e) => handleChange(e, setFormData)}
                />{" "}
                <p className="text-danger">{error.question}</p>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup id="answer">
                <FormLabel className="fontweigh-500">
                  Answer<span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  required
                  as="textarea"
                  rows="3"
                  name="answer"
                  value={formData.answer}
                  onChange={(e) => handleChange(e, setFormData)}
                  placeholder="Answer"
                />
                <p className="text-danger">{error.comment}</p>
              </FormGroup>
            </Col>

            <Col sm={6} className="mb-3">
              <FormGroup>
                <FormLabel className="fontweigh-500">Categories</FormLabel>
                <Select
                  name="category_id"
                  placeholder="Categories"
                  value={valuehandler(faqCatOptions, formData.category_id)}
                  options={faqCatOptions}
                  onChange={(data) =>
                    setFormData({ ...formData, category_id: data.value })
                  }
                />
                <p className="text-danger">{error.category_id}</p>
              </FormGroup>
            </Col>
            <Col sm={6} className="mb-3">
              <FormGroup id="status">
                <FormLabel className="fontweigh-500">Is Active</FormLabel>
                <Select
                  name="status"
                  placeholder="Status"
                  value={valuehandler(yesNoOptions, formData.status)}
                  options={yesNoOptions}
                  onChange={(data) =>
                    setFormData({ ...formData, status: data.value })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline-danger"
            className="fontweigh-500"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button className="green-btn fontweigh-500" onClick={handleSave}>
            Save Changes
          </Button>
        </ModalFooter>
      </Modal>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};
export default AddEditFaq;
