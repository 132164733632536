import { Accordion, Container } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import ServerError from "../../Login/ServerError";
import { useEffect } from "react";
import axios from "../../../axios";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../../redux/loadingSlice";
import LoadingPlaceHolder from "../../../reusable/Helper/LoadingPlaceHolder";

const FAQPage = () => {
  const dispatch = useDispatch();
  // Functions
  const fetchFaq = async () => {
    const res = await axios(`/get/faqs`);
    return res.data.data.categories;
  };

  const {
    data: categories = [],
    isLoading: faqLoading,
    error: faqError,
  } = useQuery({
    queryKey: ["faqs-categories"],
    queryFn: fetchFaq,
  });

  // useEffects
  useEffect(() => {
    dispatch(loadingSet(faqLoading));
  }, [faqLoading, dispatch]);

  if (faqError) {
    return (
      <ServerError
        errorMessage={faqError?.response?.data?.message || faqError}
      />
    );
  }
  return (
    <Container className="section-container mx-auto  my-5 footer-accordion">
      <h1 className="text-center fs-3 fontweigh-500 mb-4">
        Frequently Asked Questions (FAQs)
      </h1>
      {categories.map((category) => (
        <>
          <h2 className="fs-5 fontweigh-500">{category.category_name}</h2>
          <Accordion defaultActiveKey="-1">
            {category.faqs.map((faq, index) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={faq.id}
                className="mb-2"
              >
                <Accordion.Header>
                  <p className="fw-bold m-0">
                    Q{index + 1} {faq.question}
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <p>{faq.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <hr className="my-5" />
        </>
      ))}
      {faqLoading && <LoadingPlaceHolder />}
    </Container>
  );
};
export default FAQPage;
